:root {
  /* Variables */
  --bg-color: #f0f0f0;
  --box-color: #ffffff;
  --pop-color: #431DB1;
  --pop-color-fade: #421db12a;
  --text-color: #252525;
  --text-color-error: rgb(121, 17, 17);

  background-color: var(--bg-color);
  color: var(--text-color);
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}