.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.pop {
  color: var(--pop-color);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  font-family: Poppins;
  color: white;
  background-color: var(--pop-color);
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: bold;
  transition: ease-in-out 0.2s;
  font-size: 10pt;
  text-decoration: none;
}

.btn:hover {
  background-color: var(--pop-color-fade);
  color: var(--pop-color);
}

.lnk,
.lnk:visited,
lnk:active {
  color: var(--pop-color);
  text-decoration: none;
  padding: 5px 10px;
  margin: 0 5px;
  font-size: 10pt;
}

.lnk:hover {
  background-color: var(--pop-color-fade);
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  transition: ease-in-out 0.2s;
}

.header {
  padding: 5px;
  margin: 0 -50px;
  box-shadow: 15px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  background-color: var(--box-color);
  width: 80vw;
  padding: 5px 25px;
  margin-top: 25px;
  border-radius: 50px;
}

.logo {
  width: 80px;
}

.body {
  width: 80vw;
  min-height: 80vh;
  display: flex;
  align-items: center;
}

.top {
  margin-top: 40px;
}

.headline {
  width: 40%;
}

.headline h1 {
  font-weight: 900;
}

.headline h4 {
  font-weight: 400;
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.file-drop {
  transition: ease-in-out 0.2s;
  flex-direction: column;
  border: 2px dashed var(--pop-color-fade);
  border-radius: 10px;
  padding: 10px;
}

.file-drop:active,
.file-drop:hover {
  background-color: var(--pop-color-fade);
}

.container {
  border: 1px solid var(--pop-color-fade);
  border-radius: 20px;
  padding: 25px;
  background-color: var(--box-color);
  width: 40%;
  margin-bottom: 25px;
}

.folder {
  color: var(--pop-color);
}

.progress {
  width: 100%;
  color: white;
  height: 50px;
}

.footer {
  width: 100%;
  background-color: white;
}

.footer-container {
  text-align: center;
}

.error {
  font-size: 9pt;
  margin-top: 15px;
  padding: 5px;
  border: 1px solid var(--text-color-error);
  border-radius: 5px;
  background-color: rgba(255, 70, 70, 0.377);
  color: var(--text-color-error);
}

.mb-0 {
  margin-bottom: 0;
}

@media screen and (max-width: 780px) {
  .main {
    flex-direction: column;
  }

  .container,
  .headline {
    width: 100%;
  }
}